import axios from "axios";

function api(method, url, params, data) {
  const axiosRequest = axios.create({
    baseURL: process.env.REACT_APP_HOST,
  });

  let headers = {
    Accept: "application/json",
    authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  return axiosRequest({ method, url, params, data, headers })
    .then((response) => {
      return handleAxiosResponse(response);
    })
    .catch((error) => {
      return handleAxiosResponse(error.response);
    });
}

function formDataApi(method, url, _data, file, fieldName) {
  const axiosRequest = axios.create({
    baseURL: process.env.REACT_APP_HOST,
  });

  var newFormData = new FormData();
  newFormData.append("data", JSON.stringify(_data));
  newFormData.append(fieldName, file);

  let data = newFormData;
  let headers = {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  return axiosRequest({ method, url, data, headers })
    .then((response) => {
      return handleAxiosResponse(response);
    })
    .catch((error) => {
      return handleAxiosResponse(error.response);
    });
}
function handleAxiosResponse(response) {
  return new Promise((resolve, reject) => {
    const data = response.data;
    switch (Math.floor(response.status / 100)) {
      case 2:
        if (!data.status) {
          return reject(data.message);
        }
        return resolve(data.result);
      case 4:
        if (response.status === 401) {
          // localStorage.removeItem("accessToken");
          return reject();
        }

        return reject(data.message);
      case 5:
        return reject("500 Error Server");
      default:
        return reject("Unknown Error");
    }
  });
}

export const publicService = {
  api,
  formDataApi,
};
