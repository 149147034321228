import React from "react";
import { Input, Row, Switch, Button, Form, Modal, Upload, Select, DatePicker } from "antd";
import { DeleteOutlined, SearchOutlined, CloudUploadOutlined } from "@ant-design/icons";

import styled from "styled-components";
import { LoaderContext, ViewContext } from "../../../providers";
import { publicService } from "../../../_service";
import { getImage } from "../../../_helpers";
const { Dragger } = Upload;

export const NewAffiliation = ({ updateList }) => {
  // const { theme } = useTheme();

  const { setIsLoading, notif } = React.useContext(LoaderContext);

  const [form] = Form.useForm();

  const add = async () => {
    try {
      const { percent } = form?.getFieldsValue();
      const data = {
        percent: parseInt(percent),
      };

      // console.log(">>>>>>>>>>>", data);
      setIsLoading(true);
      let result = await publicService.api("POST", "/affiliation", {}, data);
      console.log(result);
      setIsLoading(false);

      updateList(result);
    } catch (error) {
      setIsLoading(false);

      notif("error", error?.message || error);
    }
  };

  return (
    <Container>
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 12 }}
        onFinish={add}
      >
        <Form.Item
          name="percent"
          label="percent"
          rules={[
            {
              required: true,
              message: "percent is required",
            },
            {
              validator: (_, value) =>
                isNaN(parseInt(value))
                  ? Promise.reject(new Error("percent must be integer"))
                  : Promise.resolve(),
            },
            {
              validator: (_, value) =>
                parseInt(value) > 100 || parseInt(value) < 0
                  ? Promise.reject(new Error("percent must be between 0 to 100"))
                  : Promise.resolve(),
            },
          ]}
        >
          <Input size="medium" placeholder="name" />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            xs: { span: 8, offset: 0 },
            sm: { span: 8, offset: 8 },
          }}
        >
          <Button htmlType="submit" type="primary" block>
            submit
          </Button>
        </Form.Item>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  /* margin-top: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 30%;

  & > div {
    margin: 10px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  /* & > span {
		margin-bottom: 10px;
		max-width: 250px;
		width: 250px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		& input.ant-input {
			max-width: 250px;
		}
		& textarea.ant-input {
			max-width: 250px;
		}
		& span.ant-select-selection-item {
			max-width: 250px;
			width: 250px;
		}
	} */
  /* & button {
		max-width: 250px;
		width: 250px;
	} */

  /* & span.switchs_update {
		display: flex;
		flex-direction: row;
		& .ant-switch {
			margin: 0px 20px 10px 20px;
		}
	} */
`;

const ImageContainer = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: flex-start !important;
  flex-wrap: wrap;

  height: 100px;
  margin: auto;
  width: 50% !important;

  & > span {
    border-radius: 10px;
    border: 1px solid #00000020;
    padding: 2px;
    margin: 5px;
    display: flex;
    justify-content: center;
    width: auto !important;
    position: relative;
    /* display: block; */
    & img {
      width: 100px;
      height: 100px;

      object-fit: contain;
    }
    & > span {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px;
      position: absolute;
      top: 0px;
      bottom: 0px;
      right: 0px;
      left: 0px;
      border-radius: 10px;
      background: #00000030;
      z-index: 2;
      opacity: 0;
      transition: opacity 0.1s ease-in;

      & > span {
        & > svg {
          cursor: pointer;

          background: #ffffff90;
          height: 20px;
          width: 20px;
          padding: 2px;
          border-radius: 10px;
        }
      }
    }
    & > span:hover {
      opacity: 1;
    }
  }
`;
const DraggerArea = styled.div`
  width: 106px;
  height: 106px;
  margin: 5px;

  & .ant-upload.ant-upload-drag {
    border-radius: 10px;
  }

  & span.upload_area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 15px;
    & svg {
      height: 20px;
      width: 20px;
    }
  }
`;
