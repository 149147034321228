import React from "react";
// import useModal from "../Hooks/useService";
// import Modal from "./modal";
import { Spin, notification } from "antd";

import styled from "styled-components";
// import { ToastContext } from "./Toast.Provider";
// import { useHistory } from "react-router-dom";

let LoaderContext = React.createContext();

let LoaderProvider = ({ children }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const notif = (type, title, description) => {
    notification[type]({
      message: title,
      description: description,
    });
  };
  const contextValue = {
    setIsLoading: setIsLoading,
    notif: notif,
  };

  return (
    <LoaderContext.Provider value={contextValue}>
      {!isLoading ? null : (
        <SDimmer active>
          <Spin size="large" tip="Loading..." />
        </SDimmer>
      )}
      {children}
    </LoaderContext.Provider>
  );
};

const SDimmer = styled.div`
  z-index: 2000 !important;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000000c9;

  & .ant-spin.ant-spin-spinning.ant-spin-show-text {
    margin: auto;
    display: block;
    margin-top: 50vh;
  }
`;
export { LoaderContext, LoaderProvider };
