import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { Layout, Menu, Switch, Drawer, Button } from "antd";
import { UserOutlined, MenuOutlined } from "@ant-design/icons";
import { PageContent } from "./PageContent";
import "./style.css";
import { NavItems } from "../../config";
import { history } from "../../_helpers";

import { ViewContext } from "../../providers";
import { publicService } from "../../_service";
const { Header, Content, Sider } = Layout;

export const DefaultLayout = () => {
  const [collapsed, setCollapsed] = React.useState(false);
  const [drawer, setDrawer] = React.useState(false);
  let location = useLocation();

  // const { theme, toggleTheme } = useTheme();
  const { isMobile } = React.useContext(ViewContext);

  const [activeKey, setActiveKey] = React.useState([]);
  const [openKeys, setOpenKeys] = React.useState([]);

  const selectMenu = (url) => {
    // console.log(">>>>>>>>>>>", mainKey);
    // if (type === "main") {
    // 	setOpenKeys([mainKey]);
    // }
    if (isMobile) {
      setDrawer(false);
    }

    if (!url) {
      return;
    }

    if (url === "logout") {
      logOut();
      return;
    }
    history.push(url);
  };

  const logOut = async () => {
    // const token = await publicService.api("GET", "agent/signout");
    // localStorage.setItem("access_token",token)
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");

    history.push("/login");
  };
  const openDrawer = () => {
    setDrawer(true);
  };
  React.useEffect(() => {
    let path = location.pathname;
    let menuList = NavItems.map((_nav) => {
      if (_nav?.subItems?.length === 0) {
        return {
          subKey: _nav.key,
          path: _nav?.url,
        };
      }
      return _nav.subItems.map((_) => {
        return { mainKey: _nav?.key, subKey: _.key, path: _?.url };
      });
    }).flat(); //.indexOf(location.pathname);
    let selectedPath = menuList.filter((_) => _.path === path)[0];
    if (selectedPath) {
      if (selectedPath?.mainKey) {
        setActiveKey([selectedPath?.mainKey, selectedPath?.subKey]);
        setOpenKeys([selectedPath?.mainKey]);
        return;
      }

      setActiveKey([selectedPath?.subKey]);
    }
  }, [location]);

  return (
    <SLayout>
      <SDrawer
        title="Menu"
        placement="left"
        closable={true}
        onClose={() => setDrawer(false)}
        visible={drawer}
      >
        <Menu
          selectedKeys={activeKey}
          openKeys={openKeys}
          onOpenChange={(e) => setOpenKeys(e)}
          mode="inline"
        >
          {NavItems.map((nav, index) => (
            <React.Fragment key={index}>
              {nav?.subItems?.length > 0 ? (
                <Menu.SubMenu key={nav?.key} icon={<UserOutlined />} title={nav.label}>
                  {nav?.subItems?.map((sub_nav, idx) => (
                    <Menu.Item key={sub_nav?.key} onClick={() => selectMenu(sub_nav?.url)}>
                      {sub_nav?.label}
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              ) : (
                <Menu.Item key={nav?.key} onClick={() => selectMenu(nav?.url)} icon={nav.icon}>
                  {nav.label}
                </Menu.Item>
              )}
            </React.Fragment>
          ))}
        </Menu>
      </SDrawer>
      {isMobile ? null : (
        <Sider
          style={{ background: "white" }}
          className="site-layout-background"
          breakpoint="md"
          reverseArrow={false}
          collapsible
          collapsed={collapsed}
          onCollapse={() => setCollapsed(!collapsed)}
        >
          <div className="logo" />

          <Menu
            selectedKeys={activeKey}
            openKeys={openKeys}
            onOpenChange={(e) => setOpenKeys(e)}
            mode="inline"
          >
            {NavItems.map((nav, index) => (
              <React.Fragment key={index}>
                {nav?.subItems?.length > 0 ? (
                  <Menu.SubMenu key={nav?.key} icon={<UserOutlined />} title={nav.label}>
                    {nav?.subItems?.map((sub_nav, idx) => (
                      <Menu.Item key={sub_nav?.key} onClick={() => selectMenu(sub_nav?.url)}>
                        {sub_nav?.label}
                      </Menu.Item>
                    ))}
                  </Menu.SubMenu>
                ) : (
                  <Menu.Item key={nav?.key} onClick={() => selectMenu(nav?.url)} icon={nav.icon}>
                    {nav.label}
                  </Menu.Item>
                )}
              </React.Fragment>
            ))}
          </Menu>
        </Sider>
      )}
      <Layout theme="light" className="site-layout-background" style={{ minHeight: "auto" }}>
        <MainHeader ismobile={isMobile ? "yes" : "no"} collapsed={collapsed.toString()}>
          {!isMobile ? null : (
            <SButton size="large" icon={<MenuOutlined />} onClick={() => openDrawer()} />
          )}
          <h3>Admin Panel</h3>
        </MainHeader>

        <MainContent>
          <PageContent />
        </MainContent>
      </Layout>
    </SLayout>
  );
};

const SLayout = styled(Layout)`
  height: 100%;
  overflow: auto;
  background: white;
  & .ant-layout-sider-trigger {
    background: white;
    color: black;
  }
`;
const MainContent = styled(Content)`
  margin: 64px 0px 0px;
  padding: 10px;
  min-height: unset;

  height: calc(100vh - 120px);
  overflow-y: auto;
  @media (max-width: 768px) {
    padding: 5px;
  }
`;

const MainHeader = styled(Header)`
  position: fixed;
  z-index: 1;
  padding: 0px 10px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ ismobile }) => (ismobile === "yes" ? "space-between" : "center")};
  background: white;
  & h3 {
    margin: 0px;
  }
`;

const SButton = styled(Button)`
  background: ${({ theme }) => theme.bg} !important;

  border-color: ${({ theme }) => theme.bg} !important;

  & span.anticon.anticon-menu {
    color: ${({ theme }) => theme.textColor} !important;
  }
  margin-right: 10px;
`;
const SDrawer = styled(Drawer)`
  & .ant-drawer-content {
    background: ${({ theme }) => theme.bg} !important;
  }

  & .ant-drawer-header {
    background: ${({ theme }) => theme.bg} !important;
  }

  & .ant-drawer-title {
    color: ${({ theme }) => theme.textColor} !important;
  }

  & button.ant-drawer-close {
    color: ${({ theme }) => theme.textColor} !important;
  }

  & .ant-drawer-body {
    padding: 0px;
  }
`;
