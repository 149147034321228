import {
  DashboardOutlined,
  PoweroffOutlined,
  UserOutlined,
  SettingOutlined,
  CreditCardOutlined,
} from "@ant-design/icons";

export const NavItems = [
  {
    label: "Dashboard",
    url: "/admin/dashboard",
    icon: <DashboardOutlined />,
    badge: null,
    subItems: [],
    key: "1",
  },

  {
    label: "Entities",
    url: null,
    icon: <CreditCardOutlined />,
    badge: null,
    subItems: [
      {
        label: "Assets",
        url: "/admin/assets",
        icon: <SettingOutlined />,
        badge: null,
        subItems: [],
        key: "2",
      },
      {
        label: "Payment4 Commission",
        url: "/admin/commission",
        icon: <SettingOutlined />,
        badge: null,
        subItems: [],
        key: "3",
      },
      {
        label: "Affiliation Commission",
        url: "/admin/affiliation",
        icon: <SettingOutlined />,
        badge: null,
        subItems: [],
        key: "4",
      },
      {
        label: "Gateways",
        url: "/admin/gateways",
        icon: <SettingOutlined />,
        badge: null,
        subItems: [],
        key: "5",
      },
      {
        label: "Currency",
        url: "/admin/currency",
        icon: <SettingOutlined />,
        badge: null,
        subItems: [],
        key: "6",
      },
    ],
    key: "sub1",
  },

  {
    label: "Email",
    url: null,
    icon: <CreditCardOutlined />,
    badge: null,
    subItems: [
      {
        label: "Queue",
        url: "/admin/email/queue",
        icon: <SettingOutlined />,
        badge: null,
        subItems: [],
        key: "7",
      },
      {
        label: "Campaign",
        url: "/admin/email/campaign",
        icon: <SettingOutlined />,
        badge: null,
        subItems: [],
        key: "8",
      },
    ],
    key: "sub2",
  },
  // {
  //   label: 'Clients',
  //   url: '/admin/client',
  //   icon: <CreditCardOutlined />,
  //   badge: null,
  //   subItems: [],
  //   key: '5',
  // },
  // {
  //   label: 'Plans',
  //   url: '/admin/plans',
  //   icon: <CreditCardOutlined />,
  //   badge: null,
  //   subItems: [],
  //   key: '6',
  // },
  // {
  //   label: 'Voucher',
  //   url: '/admin/voucher',
  //   icon: <CreditCardOutlined />,
  //   badge: null,
  //   subItems: [],
  //   key: '7',
  // },
  // {
  //   label: 'Setting',
  //   url: '/admin/setting',
  //   icon: <CreditCardOutlined />,
  //   badge: null,
  //   subItems: [],
  //   key: '8',
  // },
  {
    label: "Log Out",
    url: "logout",
    icon: <PoweroffOutlined />,
    badge: null,
    subItems: [],
    key: "9",
  },
];
