import React from "react";
import { Input, Row, Switch, Button, Form, Modal, Upload, Select, DatePicker } from "antd";
import { DeleteOutlined, SearchOutlined, CloudUploadOutlined } from "@ant-design/icons";

import styled from "styled-components";
import { LoaderContext, ViewContext } from "../../../providers";
import { publicService } from "../../../_service";
import { assetTypes, maxSubscriberPerAsset, toEnglishNum } from "../../../_helpers";
import { getImage } from "../../../_helpers";
const { Dragger } = Upload;

export const NewAsset = ({ updateAssetList, selectedAsset, networks }) => {
  // const { theme } = useTheme();

  const { setIsLoading, notif } = React.useContext(LoaderContext);
  const [previewVisible, setPreviewVisible] = React.useState(false);
  const [previewInfo, setPreviewInfo] = React.useState(null);

  const [assetImage, setAssetImage] = React.useState();

  const [form] = Form.useForm();

  React.useEffect(() => {
    if (selectedAsset) {
      form.setFieldsValue({
        ...selectedAsset,
        networkId: networks?.find((_) => _?.symbol === selectedAsset.network?.symbol)?.id,
      });
      setAssetImage(selectedAsset?.logo);
    }
    return () => {};
  }, [selectedAsset]);

  const addAsset = async () => {
    try {
      const { name, symbol, networkId } = form?.getFieldsValue();
      const data = {
        name,
        symbol,
        logo: assetImage,
        networkId,
      };

      // console.log(">>>>>>>>>>>", data);
      setIsLoading(true);
      let result = await publicService.api(
        selectedAsset ? "PUT" : "POST",
        "/asset/",
        {},
        { ...data, ...(selectedAsset && { id: selectedAsset.id }) }
      );
      // console.log(result);
      setIsLoading(false);

      updateAssetList(result);
    } catch (error) {
      setIsLoading(false);

      notif("error", error?.message || error);
    }
  };

  const previewImage = (info) => {
    setPreviewInfo(info);
    setPreviewVisible(true);
  };

  const imageUploadRequest = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    try {
      // console.log(file);

      if (!file.type || !["image/png", "image/jpeg"].includes(file.type)) {
        let errorMessage = "invalid image format";
        onError(new Error(errorMessage));
        throw new Error(errorMessage);
      }
      if (!file.size || file.size > 300 * 1024) {
        let errorMessage = `image size is more than 300 KB >>>> ${parseInt(file.size / 1024)} KB`;
        onError(new Error(errorMessage));
        throw new Error(errorMessage);
      }
      setIsLoading(true);
      const { key } = await publicService.formDataApi("POST", "/upload/image", {}, file, "image");

      console.log(key);
      setAssetImage(key);
      // form.setFieldValue({ asseteImage: result });
      setIsLoading(false);

      onSuccess("Ok");
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      notif("error", "error occured", error?.message || error);
      // message.error(error.message || error);
    }
  };

  return (
    <Container>
      <Modal visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
        <img alt="example" style={{ width: "100%" }} src={getImage(previewInfo)} />
      </Modal>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <label>asset : </label>
        <ImageContainer>
          {assetImage ? (
            <span>
              <span>
                <DeleteOutlined onClick={() => setAssetImage()} />
                <SearchOutlined onClick={() => previewImage(assetImage)} />
              </span>
              <img src={getImage(assetImage)} alt={"assetImage"} />
            </span>
          ) : (
            <DraggerArea>
              <Dragger customRequest={imageUploadRequest} maxCount={1} showUploadList={false}>
                <span className="upload_area">
                  <CloudUploadOutlined />
                  <p>upload</p>
                </span>
              </Dragger>
            </DraggerArea>
          )}
        </ImageContainer>
      </div>

      <Form
        form={form}
        name="basic"
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 12 }}
        onFinish={addAsset}
      >
        <Form.Item
          name="name"
          label="name"
          rules={[
            {
              required: true,
              message: "name is required",
            },
          ]}
        >
          <Input size="medium" placeholder="name" />
        </Form.Item>
        <Form.Item
          name="symbol"
          label="symbol"
          rules={[
            {
              required: true,
              message: "symbol is required",
            },
          ]}
        >
          <Input size="medium" placeholder="symbol" />
        </Form.Item>

        <Form.Item
          name="networkId"
          label="network"
          initialValue={selectedAsset?.network?.symbol}
          rules={[
            {
              required: true,
              message: "network is required",
            },
          ]}
        >
          <Select style={{ width: 150 }}>
            {networks.map((network, idx) => (
              <Select.Option key={idx} value={network.id}>
                {network?.symbol}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          wrapperCol={{
            xs: { span: 8, offset: 0 },
            sm: { span: 8, offset: 8 },
          }}
        >
          <Button disabled={!assetImage} htmlType="submit" type="primary" block>
            {selectedAsset ? "update" : "add"}
          </Button>
        </Form.Item>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  /* margin-top: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 30%;

  & > div {
    margin: 10px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
`;

const ImageContainer = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: flex-start !important;
  flex-wrap: wrap;

  height: 100px;
  margin: auto;
  width: 50% !important;

  & > span {
    border-radius: 10px;
    border: 1px solid #00000020;
    padding: 2px;
    margin: 5px;
    display: flex;
    justify-content: center;
    width: auto !important;
    position: relative;
    /* display: block; */
    & img {
      width: 100px;
      height: 100px;

      object-fit: contain;
    }
    & > span {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px;
      position: absolute;
      top: 0px;
      bottom: 0px;
      right: 0px;
      left: 0px;
      border-radius: 10px;
      background: #00000030;
      z-index: 2;
      opacity: 0;
      transition: opacity 0.1s ease-in;

      & > span {
        & > svg {
          cursor: pointer;

          background: #ffffff90;
          height: 20px;
          width: 20px;
          padding: 2px;
          border-radius: 10px;
        }
      }
    }
    & > span:hover {
      opacity: 1;
    }
  }
`;
const DraggerArea = styled.div`
  width: 106px;
  height: 106px;
  margin: 5px;

  & .ant-upload.ant-upload-drag {
    border-radius: 10px;
  }

  & span.upload_area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 15px;
    & svg {
      height: 20px;
      width: 20px;
    }
  }
`;
