import React from "react";
import {
  Input,
  Row,
  Switch,
  Button,
  Form,
  Modal,
  Upload,
  Select,
  DatePicker,
  Drawer,
} from "antd";
import {
  DeleteOutlined,
  SearchOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons";

import styled from "styled-components";
import { LoaderContext, ViewContext } from "../../../providers";
import { publicService } from "../../../_service";

const defaultEmailTitles = ["Payment4"];
const defaultEmailSubjects = ["Pay By Crypto"];
const defaultEmailHtmlFileNames = ["m1"];
const defaultEmailDestinationUrls = ["https://payment4.com/fa"];
const defaultEmailType = ["MARKETING"];

export const NewMailQueueForm = ({ isOpen, close, updateList }) => {
  const [form] = Form.useForm();

  const { setIsLoading, notif } = React.useContext(LoaderContext);

  const add = async () => {
    try {
      const {
        type,
        destinationUrl,
        htmlFileName,
        subject,
        title,
        pass,
        user,
        to,
        from,
      } = form?.getFieldsValue();
      const data = {
        from,
        type,
        destinationUrl,
        htmlFileName,
        subject,
        title,
        pass,
        user,
        to: to.split("\n"),
      };

      setIsLoading(true);
      const { status, id } = await publicService.api(
        "POST",
        "/bulk-mailer/create/queue",
        {},
        data
      );
      if (status !== "success") {
        throw new Error("something went wrong");
      }
      setIsLoading(false);

      updateList();
      close();
    } catch (error) {
      setIsLoading(false);

      notif("error", error?.message || error);
    }
  };

  return (
    <Drawer
      destroyOnClose
      maskStyle={{
        backdropFilter: "blur(3px)",
        WebkitBackdropFilter: "blur(3px)",
        WebkitBackdropFilter: "blur(3px)",
      }}
      title={`create new email queue`}
      placement={"right"}
      closable={true}
      onClose={() => close()}
      visible={isOpen}
      key="new_mail_queue"
      width={"400"}
    >
      <Container>
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          onFinish={add}
        >
          <Form.Item
            name="from"
            label="from"
            rules={[
              {
                required: true,
                message: "from is required",
              },
              {
                type: "email",
                message: "invalid email",
              },
            ]}
          >
            <Input size="medium" placeholder="from" />
          </Form.Item>

          <Form.Item
            name="user"
            label="user"
            rules={[
              {
                required: true,
                message: "user is required",
              },
            ]}
          >
            <Input size="medium" placeholder="user" />
          </Form.Item>
          <Form.Item
            name="pass"
            label="pass"
            rules={[
              {
                required: true,
                message: "password is required",
              },
            ]}
          >
            <Input size="medium" placeholder="password" />
          </Form.Item>
          <Form.Item name="title" label="title">
            <Select style={{ width: 200 }} allowClear>
              {defaultEmailTitles.map((title, idx) => (
                <Select.Option key={idx} value={title}>
                  {title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="subject" label="subject">
            <Select style={{ width: 200 }} allowClear>
              {defaultEmailSubjects.map((subject, idx) => (
                <Select.Option key={idx} value={subject}>
                  {subject}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="htmlFileName" label="file name">
            <Select style={{ width: 200 }} allowClear>
              {defaultEmailHtmlFileNames.map((fileName, idx) => (
                <Select.Option key={idx} value={fileName}>
                  {fileName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="destinationUrl" label="destination url">
            <Select style={{ width: 200 }} allowClear>
              {defaultEmailDestinationUrls.map((destinationUrl, idx) => (
                <Select.Option key={idx} value={destinationUrl}>
                  {destinationUrl}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="type" label="email type">
            <Select style={{ width: 200 }} allowClear>
              {defaultEmailType.map((type, idx) => (
                <Select.Option key={idx} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="to"
            label="to"
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 22 }}
            rules={[
              {
                required: true,
                message: "to is required",
              },
            ]}
          >
            <Input.TextArea size="medium" placeholder="to" />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              xs: { span: 8, offset: 0 },
              sm: { span: 8, offset: 8 },
            }}
          >
            <Button htmlType="submit" type="primary" block>
              submit
            </Button>
          </Form.Item>
        </Form>
      </Container>
    </Drawer>
  );
};

const Container = styled.div`
  /* margin-top: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* height: 30%; */

  & > div {
    margin: 10px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  /* & > span {
		margin-bottom: 10px;
		max-width: 250px;
		width: 250px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		& input.ant-input {
			max-width: 250px;
		}
		& textarea.ant-input {
			max-width: 250px;
		}
		& span.ant-select-selection-item {
			max-width: 250px;
			width: 250px;
		}
	} */
  /* & button {
		max-width: 250px;
		width: 250px;
	} */

  /* & span.switchs_update {
		display: flex;
		flex-direction: row;
		& .ant-switch {
			margin: 0px 20px 10px 20px;
		}
	} */
`;
