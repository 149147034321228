import React from "react";
import { Input, Row, Switch, Button, Form, Modal, Upload, Select, DatePicker } from "antd";
import { DeleteOutlined, SearchOutlined, CloudUploadOutlined } from "@ant-design/icons";

import styled from "styled-components";
import { LoaderContext, ViewContext } from "../../../providers";
import { publicService } from "../../../_service";
import { Level } from "./level";

const defaultCommissionLevel = {
  min: null,
  max: null,
  percentage: null,
  constant: null,
  maxCommission: null,
};

export const NewCommission = ({ updateCommissionList, selectedCommission }) => {
  // const { theme } = useTheme();

  const { setIsLoading, notif } = React.useContext(LoaderContext);
  const [levels, setLevels] = React.useState([defaultCommissionLevel]);
  const [name, setName] = React.useState();

  React.useEffect(() => {
    if (selectedCommission) {
      setName(selectedCommission.name);
      setLevels(selectedCommission?.info);
    }
    return () => {};
  }, [selectedCommission]);

  const upsertCommission = async () => {
    try {
      // console.log(">>>>>>>>>>>", levels);

      const data = {
        name,
        info: levels,
      };
      // console.log(">>>>>>>>>>>", data);
      setIsLoading(true);
      let result = await publicService.api(
        selectedCommission ? "PUT" : "POST",
        selectedCommission ? `/commission/${selectedCommission.id}` : "/commission",
        {},
        data
      );
      console.log(result);
      setIsLoading(false);

      updateCommissionList(result);
    } catch (error) {
      setIsLoading(false);

      notif("error", error?.message || error);
    }
  };

  const updateInfoAtIndex = (info, index) => {
    setLevels((prev) =>
      prev.map((_, idx) => {
        if (idx === index) {
          return { ..._, ...info };
        }
        return _;
      })
    );
  };
  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginRight: 20,
          }}
        >
          <label>name</label>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{ margin: 5, width: 150 }}
            size="medium"
          />
        </span>
        <Button
          disabled={!name || !levels?.length}
          onClick={() => upsertCommission()}
          type="primary"
        >
          {selectedCommission ? "update" : "add"}
        </Button>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          width: "100%",
        }}
      >
        <div style={{ marginBottom: 10 }}>
          <Button onClick={() => setLevels((prev) => [...prev, defaultCommissionLevel])}>
            Add Level +
          </Button>
        </div>
        <div>
          {levels.map((_, index) => (
            <Level
              key={index}
              setInfo={(info) => updateInfoAtIndex(info, index)}
              info={_}
              remove={() => setLevels((prev) => prev.filter((_, idx) => index !== idx))}
            />
          ))}
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  /* margin-top: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* height: 30%; */

  & > div {
    margin: 10px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  /* & > span {
		margin-bottom: 10px;
		max-width: 250px;
		width: 250px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		& input.ant-input {
			max-width: 250px;
		}
		& textarea.ant-input {
			max-width: 250px;
		}
		& span.ant-select-selection-item {
			max-width: 250px;
			width: 250px;
		}
	} */
  /* & button {
		max-width: 250px;
		width: 250px;
	} */

  /* & span.switchs_update {
		display: flex;
		flex-direction: row;
		& .ant-switch {
			margin: 0px 20px 10px 20px;
		}
	} */
`;
