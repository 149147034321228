import React from "react";

import { Redirect, Route, Switch, BrowserRouter, Router as ReactRouter } from "react-router-dom";
import { Login, Register, Home } from "./Pages";
import { DefaultLayout } from "./DefaultLayout/DefaultLayout";
import { history } from "../_helpers";

const Router = () => {
  // const AdminPrivateRoute = ({ component: Component, ...rest }) => {
  // 	const hasToken = localStorage.getItem("access_token");
  // 	return <Route {...rest} render={(props) => (hasToken ? <Component {...props} /> : <Redirect to={{ pathname: "/login", state: { from: props.location } }} />)} />;
  // };
  const AdminPrivateRoute = ({ component: Component, ...rest }) => {
    const hasToken = localStorage.getItem("accessToken");

    return (
      <Route
        {...rest}
        render={(props) =>
          hasToken ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
          )
        }
      />
    );
  };

  return (
    <Switch>
      <Route exact path="/login" name="Login Page" component={Login} />

      <AdminPrivateRoute path="/admin" name="dashboard" component={DefaultLayout} />

      <Redirect from="/*" to="/login" />
    </Switch>
  );
};

export default Router;
