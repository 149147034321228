import React from "react";

import styled from "styled-components";
import { RouterProvider, ViewProvider, LoaderProvider } from "../providers";
import Router from "./Router";
import { ConfigProvider } from "antd";
// import { SecurePage } from "./SecurePage";

const Main = () => {
  return (
    <RouterProvider>
      <LoaderProvider>
        <ViewProvider>
          <ConfigProvider>
            <Router />
          </ConfigProvider>
        </ViewProvider>
      </LoaderProvider>
    </RouterProvider>
  );
};

export default Main;
