import {
  Affiliation,
  Asset,
  Commission,
  Dashboard,
  EmailCampaignList,
  EmailQueueList,
  Gateway,
  Network,
} from "../views/Admin";
import { Currency } from "../views/Admin/currency/currency";

export const RouteItems = [
  {
    path: "/admin/dashboard",
    exact: true,
    name: "Dashboard",
    component: Dashboard,
  },
  // { path: "/admin/wallet", exact: true, name: "tickets", component: Wallet },
  {
    path: "/admin/commission",
    exact: true,
    name: "commissions",
    component: Commission,
  },
  { path: "/admin/assets", exact: true, name: "assets", component: Asset },
  {
    path: "/admin/gateways",
    exact: true,
    name: "gateways",
    component: Gateway,
  },
  {
    path: "/admin/currency",
    exact: true,
    name: "currency",
    component: Currency,
  },
  {
    path: "/admin/affiliation",
    exact: true,
    name: "affiliation",
    component: Affiliation,
  },
  {
    path: "/admin/email/queue",
    exact: true,
    name: "email queue",
    component: EmailQueueList,
  },
  {
    path: "/admin/email/campaign",
    exact: true,
    name: "email campaign",
    component: EmailCampaignList,
  },

  // { path: '/admin/client', exact: true, name: 'Clients', component: Clients },

  // { path: '/admin/plans', exact: true, name: 'Plan', component: Plans },
  // { path: '/admin/voucher', exact: true, name: 'Voucher', component: Voucher },

  // { path: '/admin/vps', exact: true, name: 'vps', component: Vps },
];
