import React from "react";
import { Input, Row, Switch, Button, Form, Modal, Upload, Select, DatePicker } from "antd";
import { DeleteOutlined, SearchOutlined, CloudUploadOutlined } from "@ant-design/icons";

import styled from "styled-components";
import { LoaderContext, ViewContext } from "../../../providers";
import { publicService } from "../../../_service";
import { getImage } from "../../../_helpers";
const { Dragger } = Upload;

export const NewNetwork = ({ updateNetworkList, selectedNetwork }) => {
  // const { theme } = useTheme();

  const { setIsLoading, notif } = React.useContext(LoaderContext);
  const [previewVisible, setPreviewVisible] = React.useState(false);
  const [previewInfo, setPreviewInfo] = React.useState(null);
  const [networkImage, setNetworkImage] = React.useState();

  const [form] = Form.useForm();

  React.useEffect(() => {
    if (selectedNetwork) {
      form.setFieldsValue({
        ...selectedNetwork,
      });
      setNetworkImage(selectedNetwork?.logo);
    }
    return () => {};
  }, [selectedNetwork]);

  const upsertNetwork = async () => {
    try {
      const { name, symbol, pattern } = form?.getFieldsValue();
      const data = {
        name,
        symbol,
        pattern,
        logo: networkImage,
      };

      // console.log(">>>>>>>>>>>", data);
      setIsLoading(true);
      let result = await publicService.api(
        selectedNetwork ? "PUT" : "POST",
        "/asset/network",
        {},
        { ...data, ...(selectedNetwork && { id: selectedNetwork.id }) }
      );
      console.log(result);
      setIsLoading(false);

      updateNetworkList(result);
    } catch (error) {
      setIsLoading(false);

      notif("error", error?.message || error);
    }
  };

  const previewImage = (info) => {
    setPreviewInfo(info);
    setPreviewVisible(true);
  };

  const imageUploadRequest = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    try {
      // console.log(file);

      if (!file.type || !["image/png", "image/jpeg"].includes(file.type)) {
        let errorMessage = "invalid image format";
        onError(new Error(errorMessage));
        throw new Error(errorMessage);
      }
      if (!file.size || file.size > 300 * 1024) {
        let errorMessage = `image size is more than 300 KB >>>> ${parseInt(file.size / 1024)} KB`;
        onError(new Error(errorMessage));
        throw new Error(errorMessage);
      }
      setIsLoading(true);
      const { key } = await publicService.formDataApi("POST", "/upload/image", {}, file, "image");

      console.log(key);
      setNetworkImage(key);
      // form.setFieldValue({ asseteImage: result });
      setIsLoading(false);

      onSuccess("Ok");
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      notif("error", "error occured", error?.message || error);
      // message.error(error.message || error);
    }
  };
  return (
    <Container>
      <Modal visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
        <img alt="example" style={{ width: "100%" }} src={getImage(previewInfo)} />
      </Modal>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <label>asset : </label>
        <ImageContainer>
          {networkImage ? (
            <span>
              <span>
                <DeleteOutlined onClick={() => setNetworkImage()} />
                <SearchOutlined onClick={() => previewImage(networkImage)} />
              </span>
              <img src={getImage(networkImage)} alt={"networkImage"} />
            </span>
          ) : (
            <DraggerArea>
              <Dragger customRequest={imageUploadRequest} maxCount={1} showUploadList={false}>
                <span className="upload_area">
                  <CloudUploadOutlined />
                  <p>upload</p>
                </span>
              </Dragger>
            </DraggerArea>
          )}
        </ImageContainer>
      </div>
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 12 }}
        onFinish={upsertNetwork}
      >
        <Form.Item
          name="name"
          label="name"
          rules={[
            {
              required: true,
              message: "name is required",
            },
          ]}
        >
          <Input size="medium" placeholder="name" />
        </Form.Item>
        <Form.Item
          name="symbol"
          label="symbol"
          rules={[
            {
              required: true,
              message: "symbol is required",
            },
          ]}
        >
          <Input size="medium" placeholder="symbol" />
        </Form.Item>
        <Form.Item
          name="pattern"
          label="pattern"
          rules={[
            {
              required: true,
              message: "pattern is required",
            },
          ]}
        >
          <Input size="medium" placeholder="pattern" />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            xs: { span: 8, offset: 0 },
            sm: { span: 8, offset: 8 },
          }}
        >
          <Button htmlType="submit" type="primary" block>
            {selectedNetwork ? "update" : "add"}
          </Button>
        </Form.Item>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  /* margin-top: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 30%;

  & > div {
    margin: 10px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  /* & > span {
		margin-bottom: 10px;
		max-width: 250px;
		width: 250px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		& input.ant-input {
			max-width: 250px;
		}
		& textarea.ant-input {
			max-width: 250px;
		}
		& span.ant-select-selection-item {
			max-width: 250px;
			width: 250px;
		}
	} */
  /* & button {
		max-width: 250px;
		width: 250px;
	} */

  /* & span.switchs_update {
		display: flex;
		flex-direction: row;
		& .ant-switch {
			margin: 0px 20px 10px 20px;
		}
	} */
`;

const ImageContainer = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: flex-start !important;
  flex-wrap: wrap;

  height: 100px;
  margin: auto;
  width: 50% !important;

  & > span {
    border-radius: 10px;
    border: 1px solid #00000020;
    padding: 2px;
    margin: 5px;
    display: flex;
    justify-content: center;
    width: auto !important;
    position: relative;
    /* display: block; */
    & img {
      width: 100px;
      height: 100px;

      object-fit: contain;
    }
    & > span {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px;
      position: absolute;
      top: 0px;
      bottom: 0px;
      right: 0px;
      left: 0px;
      border-radius: 10px;
      background: #00000030;
      z-index: 2;
      opacity: 0;
      transition: opacity 0.1s ease-in;

      & > span {
        & > svg {
          cursor: pointer;

          background: #ffffff90;
          height: 20px;
          width: 20px;
          padding: 2px;
          border-radius: 10px;
        }
      }
    }
    & > span:hover {
      opacity: 1;
    }
  }
`;
const DraggerArea = styled.div`
  width: 106px;
  height: 106px;
  margin: 5px;

  & .ant-upload.ant-upload-drag {
    border-radius: 10px;
  }

  & span.upload_area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 15px;
    & svg {
      height: 20px;
      width: 20px;
    }
  }
`;
