import React from "react";
import { Input, Space, Row, Col, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { publicService } from "../../_service";
import { history } from "../../_helpers";

import { LoaderContext, ViewContext } from "../../providers";
import styled from "styled-components";

export const Login = () => {
  const [user, setUser] = React.useState({ email: "", password: "" });
  const { notif, setIsLoading } = React.useContext(LoaderContext);
  const { isMobile } = React.useContext(ViewContext);

  const login = async () => {
    try {
      if (user.email.length === 0 || user.password.length === 0) {
        throw new Error("all fields are required");
      }

      let emailCheckRegex = /\S+@\S+\.\S+/;
      if (!emailCheckRegex.test(user.email)) {
        throw new Error("email in not valid");
      }
      let info = {
        email: user.email.toLowerCase(),

        password: user.password,
      };

      setIsLoading(true);
      const { accessToken, refreshToken } = await publicService.api(
        "POST",
        "/user/admin/signin",
        {},
        info
      );
      setIsLoading(false);
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);

      // localStorage.setItem("access_token", token);
      // setLoginState("verified");
      history.push("/admin");
    } catch (error) {
      setIsLoading(false);

      notif("error", error.message || error);
    }
  };
  return (
    <Container>
      <Srow>
        <h2>Admin Panel</h2>
      </Srow>
      <Srow>
        <Col span={isMobile ? 18 : 6}>
          <Space size="large" direction="vertical">
            <Input
              size="medium"
              placeholder="email"
              prefix={<UserOutlined />}
              onChange={(e) => setUser({ ...user, email: e.target.value.toLowerCase() })}
            />
            <Input.Password
              prefix={<UserOutlined />}
              placeholder="password"
              onChange={(e) => setUser({ ...user, password: e.target.value })}
            />
            <Button type="primary" block onClick={() => login()}>
              Login
            </Button>
          </Space>
        </Col>
      </Srow>
    </Container>
  );
};
const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & p {
    text-align: center;
  }
`;
const Srow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & .ant-space-vertical {
    width: 100%;
  }
  /* & .ant-input-affix-wrapper {
		direction: ltr !important;
	} */

  & .ant-input {
    margin-left: 20px;
  }
`;
