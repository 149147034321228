import React, { useState, useEffect, useContext } from "react";
import { Tabs, Card, Col, Button } from "antd";
import styled from "styled-components";
import { Container } from ".";
import "./style.css";

export const Dashboard = () => {
  //   return (
  //     <SocketProvider>
  //       <DefaultDashboard />
  //     </SocketProvider>
  //   );
  return <p>dashboard</p>;
};
const STabs = styled(Tabs)`
  padding-left: 10px;
  & .ant-tabs-tab {
    background: transparent !important;
  }

  & :before {
    border-bottom-color: ${({ theme }) => theme.lineColor} !important;
  }

  /* & .ant-tabs-tab-active {
		border-bottom-width: 0px !important;
	} */
`;

const SButton = styled(Button)`
  margin: 15px auto auto 15px;

  /* & .ant-tabs-tab-active {
		border-bottom-width: 0px !important;
	} */
`;
