import React from "react";
import { history } from "../_helpers";
import { Router, Switch } from "react-router-dom";
// import { Switch } from "antd";

const RouterProvider = ({ children }) => {
	return (
		<Router history={history}>
			<Switch>{children}</Switch>
		</Router>
	);
};

export { RouterProvider };
