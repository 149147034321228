// import { ThemeProvider } from "./providers/Theme.Provider";
// import styled, { createGlobalStyle } from "styled-components";
import "./App.css";
import Main from "./views/Main";
// import Com from "./views/Com";

function App() {
	return <Main />;
}

export default App;
