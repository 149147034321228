import React from "react";
import styled from "styled-components";
import { publicService } from "../../../_service";
import { LoaderContext } from "../../../providers";
import { Card, Table, Button, Input, Menu, Dropdown, Switch } from "antd";
import moment from "moment";

export const Currency = () => {
  const { setIsLoading, notif } = React.useContext(LoaderContext);
  const [list, setList] = React.useState([]);

  const getCurrencys = async () => {
    try {
      setIsLoading(true);

      const Currencys = await publicService.api("GET", "/currency");
      setList(Currencys);
      setIsLoading(false);
    } catch (error) {
      notif("error", error?.message || error);

      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    getCurrencys();
    return () => {};
  }, []);

  const getCurrencyStatusBtn = (status) => {
    if (status === "VERIFIED") {
      return "primary";
    }

    if (status === "INACTIVE") {
      return "default";
    }

    if (status === "LIMITED") {
      return "danger";
    }
    if (status === "REJECT") {
      return "danger";
    }

    return "default";
  };

  const columns = [
    {
      title: "id",
      render: (record, index, idx) => <>{record.id} </>,
      width: 20,
      fixed: "left",
    },

    {
      title: "name",
      render: (record) => <>{record?.name} </>,
      width: 70,
    },

    {
      title: "price",
      render: (record) => <>{record?.price} </>,
      width: 70,
    },

    {
      title: "updated at",
      render: (record) => <>{moment(record?.updatedAt).fromNow()} </>,
      width: 70,
    },
  ];

  return (
    <Container>
      <div className="action_box">
        <p>Currency list - count : {list?.length}</p>
      </div>

      <div>
        <Card size="small" bordered={false} style={{ width: "100%" }}>
          <Table
            // scroll={{
            //   x: 900,
            //   y: window.innerHeight - 300,
            // }}
            bordered
            rowKey={(record) => record.id}
            dataSource={list}
            columns={columns}
            pagination={null}
          />
        </Card>
      </div>
    </Container>
  );
};

const Container = styled.div`
  & .action_box {
    background-color: white;
    margin: 0px;
    padding: 10px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    & p {
      margin: 0;
    }
  }

  & .networks {
    background-color: white;
    margin: 0px;
    padding: 10px 10px;
    display: flex;
    flex-direction: column;

    margin-bottom: 20px;
    & p {
      margin: 0;
    }
    & span:first-child {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }

    & span:nth-child(2) {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      & button {
        margin-right: 10px;
      }
    }
  }
`;
