import React from "react";
import styled from "styled-components";

import { publicService } from "../../../_service";
import { LoaderContext } from "../../../providers";

import { Card, Table, Button, Input, Drawer, Dropdown, Switch } from "antd";
import { NewAsset } from "./new.asset";
import { NewNetwork } from "./new.network";
import { getImage } from "../../../_helpers";

export const Asset = () => {
  const { setIsLoading, notif } = React.useContext(LoaderContext);
  const [list, setList] = React.useState([]);
  const [networks, setNetworks] = React.useState([]);
  const [selectedNetwork, setSelectedNetwork] = React.useState();
  const [networkDrawer, setNetworkDrawer] = React.useState(false);

  const [selectedAsset, setSelectedAsset] = React.useState();
  const [assetDrawer, setAssetDrawer] = React.useState(false);

  const getAssets = async () => {
    try {
      setIsLoading(true);

      const { assets, networks } = await publicService.api("GET", "/asset/all");
      setNetworks(networks);
      setList(assets);

      setIsLoading(false);
    } catch (error) {
      notif("error", error?.message || error);

      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    getAssets();
    return () => {};
  }, []);

  const openAssetDrawer = (asset) => {
    setSelectedAsset(asset);
    setAssetDrawer(true);
  };

  const openNetworkDrawer = (network) => {
    setSelectedNetwork(network);
    setNetworkDrawer(true);
  };

  const changeAssetState = async (asset, field, newStatus) => {
    try {
      setIsLoading(true);
      const updated = await publicService.api(
        "PUT",
        "/asset/",
        {},
        { ...asset, [field]: newStatus, networkId: asset.network.id }
      );
      // console.log(updated);
      updateAssetList(updated);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      notif("error", error?.message || error);
    }
  };

  const columns = [
    {
      title: "id",
      render: (record, index, idx) => <>{record.id} </>,
      width: 20,
      fixed: "left",
    },
    {
      title: "logo",
      render: (record) => (
        <img style={{ width: 30, height: 30 }} src={getImage(record.logo)} alt={record.symbol} />
      ),
      width: 70,
    },
    {
      title: "symbol",
      render: (record) => <>{record?.symbol} </>,
      width: 70,
    },
    {
      title: "name",
      render: (record) => <>{record?.name} </>,
      width: 70,
    },

    {
      title: "network",
      render: (record) => <>{record?.network?.symbol} </>,
      width: 70,
    },
    {
      title: "price ($)",
      render: (record) => <>{record?.price} </>,
      width: 70,
    },

    {
      title: "available",
      render: (record) => (
        <SSwitch
          onChange={() => changeAssetState(record, "isActive", !record.isActive)}
          defaultChecked={record?.isActive}
          checked={record?.isActive}
        />
      ),
      width: 50,
    },
    {
      title: "update",
      render: (record) => (
        <Button onClick={() => openAssetDrawer(record)} type="primary">
          update
        </Button>
      ),
      width: 80,
    },
  ];

  const updateAssetList = (newAsset) => {
    if (list.find((_) => _?.id === newAsset?.id)) {
      const newList = list.map((_) => {
        if (_.id === newAsset?.id) {
          return { ..._, ...newAsset };
        }
        return _;
      });

      setList(newList);

      // setFilteredList(newFilteredList);
    } else {
      setList([newAsset, ...list]);
      // setFilteredList([...list, newAsset]);
    }

    setAssetDrawer(false);
  };

  const updateNetworkList = (newNetwork) => {
    setNetworkDrawer(false);

    if (networks.find((_) => _?.id === newNetwork?.id)) {
      const newList = networks.map((_) => {
        if (_.id === newNetwork?.id) {
          return { ..._, ...newNetwork };
        }
        return _;
      });

      setNetworks(newList);

      return;
    }
    setNetworks([...networks, newNetwork]);
  };

  return (
    <Container>
      <Drawer
        destroyOnClose
        maskStyle={{
          backdropFilter: "blur(3px)",
          WebkitBackdropFilter: "blur(3px)",
          WebkitBackdropFilter: "blur(3px)",
        }}
        title={`${selectedAsset ? "update " : "add new "}asset `}
        placement={"right"}
        closable={true}
        onClose={() => setAssetDrawer(false)}
        visible={assetDrawer}
        key="new_asset"
        width={"400px"}
      >
        <NewAsset
          updateAssetList={updateAssetList}
          selectedAsset={selectedAsset}
          networks={networks}
        />
      </Drawer>
      <Drawer
        destroyOnClose
        maskStyle={{
          backdropFilter: "blur(3px)",
          WebkitBackdropFilter: "blur(3px)",
          WebkitBackdropFilter: "blur(3px)",
        }}
        title={`${selectedNetwork ? "update " : "add new "}network `}
        placement={"right"}
        closable={true}
        onClose={() => setNetworkDrawer(false)}
        visible={networkDrawer}
        key="new_network"
        width={"400px"}
      >
        <NewNetwork updateNetworkList={updateNetworkList} selectedNetwork={selectedNetwork} />
      </Drawer>

      <div className="networks">
        <span>
          <p>network list - count : {networks?.length}</p>
          <Button onClick={() => openNetworkDrawer()} type="primary">
            add new network +
          </Button>
        </span>
        <span>
          {networks.map((network, index) => (
            <div key={index}>
              <img src={getImage(network?.logo)} ali="net" width={50} />
              <Button onClick={() => openNetworkDrawer(network)} type="dashed">
                {network.symbol}
              </Button>
            </div>
          ))}
        </span>
      </div>
      <div className="action_box">
        <p>asset list - count : {list?.length}</p>
        <Button onClick={() => openAssetDrawer()} type="primary">
          add new asset +
        </Button>
      </div>

      <div>
        <Card size="small" bordered={false} style={{ width: "100%" }}>
          <Table
            // scroll={{
            //   x: 900,
            //   y: window.innerHeight - 300,
            // }}
            bordered
            rowKey={(record) => record.id}
            dataSource={list}
            columns={columns}
            pagination={null}
          />
        </Card>
      </div>
    </Container>
  );
};

const Container = styled.div`
  & .action_box {
    background-color: white;
    margin: 0px;
    padding: 10px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    & p {
      margin: 0;
    }
  }

  & .networks {
    background-color: white;
    margin: 0px;
    padding: 10px 10px;
    display: flex;
    flex-direction: column;

    margin-bottom: 20px;
    & p {
      margin: 0;
    }
    & span:first-child {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }

    & span:nth-child(2) {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      & div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid #00000020;
        padding: 5px;
        margin-right: 10px;
        & img {
          width: 50px;
          height: 50px;
          margin-bottom: 5px;
        }
      }
    }
  }
`;

const SSwitch = styled(Switch)`
  display: block;
  margin: auto;
`;
