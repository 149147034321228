import React from "react";
import styled from "styled-components";
import { publicService } from "../../../_service";
import { LoaderContext } from "../../../providers";
import { Card, Table, Button, Input, Menu, Dropdown, Switch, Drawer } from "antd";
import moment from "moment";
import { NewAffiliation } from "./new.affiliation";

export const Affiliation = () => {
  const { setIsLoading, notif } = React.useContext(LoaderContext);
  const [list, setList] = React.useState([]);
  const [affiliationDrawer, setAffiliationDrawer] = React.useState(false);

  const getAffiliations = async () => {
    try {
      setIsLoading(true);

      const Affiliations = await publicService.api("GET", "/affiliation");
      setList(Affiliations);
      setIsLoading(false);
    } catch (error) {
      notif("error", error?.message || error);

      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    getAffiliations();
    return () => {};
  }, []);

  const changeAffiliationState = async (id) => {
    try {
      setIsLoading(true);
      const updated = await publicService.api("PUT", `/affiliation/default/${id}`);
      // console.log(updated);
      updateList(updated);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      notif("error", error?.message || error);
    }
  };

  const updateList = (el) => {
    if (list.find((_) => _?.id === el?.id)) {
      const newList = list.map((_) => {
        if (_.id === el?.id) {
          return { ..._, ...el };
        }
        return { ..._, isDefault: false };
      });

      setList(newList);

      // setFilteredList(newFilteredList);
    } else {
      setList([el, ...list]);
      // setFilteredList([...list, newAsset]);
    }

    setAffiliationDrawer(false);
  };
  const columns = [
    {
      title: "id",
      render: (record, index, idx) => <>{record.id} </>,
      width: 20,
      fixed: "left",
    },

    {
      title: "is default",
      render: (record) => (
        <SSwitch
          onChange={() => changeAffiliationState(record.id)}
          defaultChecked={record?.isDefault}
          checked={record?.isDefault}
          disabled={record?.isDefault}
        />
      ),
      width: 50,
    },
    {
      title: "percent",
      render: (record) => <>{record?.percent} </>,
      width: 70,
    },
  ];

  return (
    <Container>
      <Drawer
        destroyOnClose
        maskStyle={{
          backdropFilter: "blur(3px)",
          WebkitBackdropFilter: "blur(3px)",
          WebkitBackdropFilter: "blur(3px)",
        }}
        title={`add new affiliation commission`}
        placement={"right"}
        closable={true}
        onClose={() => setAffiliationDrawer(false)}
        visible={affiliationDrawer}
        key="new_affiliation"
        width={"400px"}
      >
        <NewAffiliation updateList={updateList} />
      </Drawer>
      <div className="action_box">
        <p>affiliation commission list - count : {list?.length}</p>
        <Button onClick={() => setAffiliationDrawer(true)} type="primary">
          add new commission +
        </Button>
      </div>

      <div>
        <Card size="small" bordered={false} style={{ width: "100%" }}>
          <Table
            // scroll={{
            //   x: 900,
            //   y: window.innerHeight - 300,
            // }}
            bordered
            rowKey={(record) => record.id}
            dataSource={list}
            columns={columns}
            pagination={null}
          />
        </Card>
      </div>
    </Container>
  );
};

const Container = styled.div`
  & .action_box {
    background-color: white;
    margin: 0px;
    padding: 10px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    & p {
      margin: 0;
    }
  }

  & .networks {
    background-color: white;
    margin: 0px;
    padding: 10px 10px;
    display: flex;
    flex-direction: column;

    margin-bottom: 20px;
    & p {
      margin: 0;
    }
    & span:first-child {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }

    & span:nth-child(2) {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      & button {
        margin-right: 10px;
      }
    }
  }
`;

const SSwitch = styled(Switch)`
  /* display: block; */
  margin: auto;
`;
