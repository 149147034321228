import React from "react";

import { Redirect, Route, Switch } from "react-router-dom";
import { RouteItems } from "../../config";
export const PageContent = () => {
	return (
		<Switch>
			{RouteItems.map((route, idx) => {
				return route.component ? <Route key={idx} path={route.path} exact={route.exact} name={route.name} render={(props) => <route.component {...props} />} /> : null;
			})}
			<Redirect from='/agent' to='/agent/dashboard' />

			{/* <Redirect from="/admin/*" to="/admin/dashboard" /> */}
		</Switch>
	);
};
