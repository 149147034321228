import React from "react";
import styled from "styled-components";

import { publicService } from "../../../_service";
import { LoaderContext } from "../../../providers";

import { Card, Table, Button, Input, Menu, Dropdown, Switch } from "antd";
import { gatewayStatus, getDateAndTime, getImage } from "../../../_helpers";
import { DownOutlined } from "@ant-design/icons";
import { NewMailQueueForm } from "./email.queue.form";

export const EmailCampaignList = () => {
  const { setIsLoading, notif } = React.useContext(LoaderContext);
  const [list, setList] = React.useState([]);
  const [newQueueDrawer, setNewQueueDrawer] = React.useState(false);

  const getQueues = async () => {
    try {
      setIsLoading(true);
      const campaigns = await publicService.api(
        "GET",
        "/bulk-mailer/campaign/list"
      );
      setList(campaigns);
      setIsLoading(false);
    } catch (error) {
      notif("error", error?.message || error);

      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    getQueues();
    return () => {};
  }, []);

  const columns = [
    {
      title: "id",
      render: (record, index, idx) => <>{record.id} </>,
      width: 20,
      fixed: "left",
    },

    {
      title: "name",
      render: (record) => <>{record?.name} </>,
      width: 70,
    },

    {
      title: "type",
      render: (record) => <>{record?.type?.toLowerCase()} </>,
      width: 70,
    },
    {
      title: "receiver count",
      render: (record) => <>{record?.receiverCount} </>,
      width: 70,
    },
    {
      title: "clicked count",
      render: (record) => <>{record?.clickCount} </>,
      width: 70,
    },
    {
      title: "date",
      render: (record) => <>{getDateAndTime(record?.createdAt)} </>,
      width: 70,
    },
  ];

  return (
    <Container>
      <div className="action_box">
        <p>email campaign list - count : {list?.length}</p>
      </div>

      <div>
        <Card size="small" bordered={false} style={{ width: "100%" }}>
          <Table
            // scroll={{
            //   x: 900,
            //   y: window.innerHeight - 300,
            // }}
            bordered
            rowKey={(record) => record.id}
            dataSource={list}
            columns={columns}
            pagination={null}
          />
        </Card>
      </div>
    </Container>
  );
};

const Container = styled.div`
  & .action_box {
    background-color: white;
    margin: 0px;
    padding: 10px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    & p {
      margin: 0;
    }
  }

  & .networks {
    background-color: white;
    margin: 0px;
    padding: 10px 10px;
    display: flex;
    flex-direction: column;

    margin-bottom: 20px;
    & p {
      margin: 0;
    }
    & span:first-child {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }

    & span:nth-child(2) {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      & button {
        margin-right: 10px;
      }
    }
  }
`;

const SSwitch = styled(Switch)`
  display: block;
  margin: auto;
`;

const DropdownButton = styled(Dropdown.Button)`
  & button.ant-btn.ant-btn-primary:first-child {
    width: 80px;
  }
`;
