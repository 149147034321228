import React from "react";
import { Grid } from "antd";

const { useBreakpoint } = Grid;

let ViewContext = React.createContext();

let ViewProvider = ({ children }) => {
	const screens = useBreakpoint();

	const [isMobile, setIsMobile] = React.useState(false);

	React.useEffect(() => {
		// if (screens.sm !== isMobile) {
		setIsMobile(!screens.sm);
		// }

		// console.log(">>>>>>>>>>>", !screens.sm);
		return () => {};
	}, [screens]);

	const contextValue = {
		isMobile: isMobile,
	};
	return <ViewContext.Provider value={contextValue}>{children}</ViewContext.Provider>;
};

export { ViewContext, ViewProvider };
