import React from "react";
import styled from "styled-components";

import { publicService } from "../../../_service";
import { LoaderContext } from "../../../providers";

import { Card, Table, Button, Input, Menu, Dropdown, Switch } from "antd";
import { gatewayStatus, getImage } from "../../../_helpers";
import { DownOutlined } from "@ant-design/icons";

export const Gateway = () => {
  const { setIsLoading, notif } = React.useContext(LoaderContext);
  const [list, setList] = React.useState([]);

  const getGateways = async () => {
    try {
      setIsLoading(true);

      const gateways = await publicService.api("GET", "/gateway/list/all");
      setList(gateways);
      setIsLoading(false);
    } catch (error) {
      notif("error", error?.message || error);

      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    getGateways();
    return () => {};
  }, []);

  const getGatewayStatusBtn = (status) => {
    if (status === "VERIFIED") {
      return "primary";
    }

    if (status === "INACTIVE") {
      return "default";
    }

    if (status === "LIMITED") {
      return "danger";
    }
    if (status === "REJECT") {
      return "danger";
    }

    return "default";
  };

  const changeGatewayStatus = async (id, newStatus) => {
    try {
      setIsLoading(true);
      const result = await publicService.api("GET", `/gateway/${id}/update/status`, {
        status: newStatus,
      });
      updateGatewayList(result);
      setIsLoading(false);
      notif("success", `gateway status change to ${result.status}`);
    } catch (error) {
      setIsLoading(false);
      notif("error", error?.message || error);
    }
  };

  const columns = [
    {
      title: "id",
      render: (record, index, idx) => <>{record.id} </>,
      width: 20,
      fixed: "left",
    },

    {
      title: "name",
      render: (record) => <>{record?.info?.name} </>,
      width: 70,
    },

    {
      title: "status",
      render: (record) => (
        <DropdownButton
          type={getGatewayStatusBtn(record.status)}
          placement="bottomLeft"
          size="small"
          trigger={["click"]}
          //   disabled={!accountStatus.find((_) => _.status === record.status)?.changeable}
          overlay={
            <Menu>
              {gatewayStatus?.map((_, idx) => (
                <Menu.Item
                  disabled={_ === record.status}
                  key={idx.toString()}
                  onClick={() => changeGatewayStatus(record?.id, _)}
                >
                  {_}
                </Menu.Item>
              ))}
            </Menu>
          }
          icon={<DownOutlined />}
        >
          {record?.status}
        </DropdownButton>
      ),
      width: 100,
    },
  ];

  const updateGatewayList = (newGateway) => {
    if (list.find((_) => _?.id === newGateway?.id)) {
      const newList = list.map((_) => {
        if (_.id === newGateway?.id) {
          return { ..._, ...newGateway };
        }
        return _;
      });

      setList(newList);

      // setFilteredList(newFilteredList);
    } else {
      setList([newGateway, ...list]);
      // setFilteredList([...list, newGateway]);
    }
  };

  return (
    <Container>
      <div className="action_box">
        <p>gateway list - count : {list?.length}</p>
      </div>

      <div>
        <Card size="small" bordered={false} style={{ width: "100%" }}>
          <Table
            // scroll={{
            //   x: 900,
            //   y: window.innerHeight - 300,
            // }}
            bordered
            rowKey={(record) => record.id}
            dataSource={list}
            columns={columns}
            pagination={null}
          />
        </Card>
      </div>
    </Container>
  );
};

const Container = styled.div`
  & .action_box {
    background-color: white;
    margin: 0px;
    padding: 10px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    & p {
      margin: 0;
    }
  }

  & .networks {
    background-color: white;
    margin: 0px;
    padding: 10px 10px;
    display: flex;
    flex-direction: column;

    margin-bottom: 20px;
    & p {
      margin: 0;
    }
    & span:first-child {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }

    & span:nth-child(2) {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      & button {
        margin-right: 10px;
      }
    }
  }
`;

const SSwitch = styled(Switch)`
  display: block;
  margin: auto;
`;

const DropdownButton = styled(Dropdown.Button)`
  & button.ant-btn.ant-btn-primary:first-child {
    width: 80px;
  }
`;
