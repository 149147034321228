import React from "react";
import styled from "styled-components";

import { publicService } from "../../../_service";
import { LoaderContext } from "../../../providers";

import { Card, Select, Button, Input, Drawer, Dropdown, Switch } from "antd";

const commissionTypes = ["Constant", "Percentage"];
const checkDefaultType = (info) => {
  if (info?.percentage && !!info?.maxCommission) {
    return "Percentage";
  }

  return "Constant";
};

export const Level = ({ info, setInfo, remove }) => {
  const [currentType, setCurrentType] = React.useState(checkDefaultType(info));

  const changeType = (e) => {
    setInfo({ ...info, percentage: null, maxCommission: null, constant: null });
    setCurrentType(e);
  };
  return (
    <div style={{ display: "flex", flexDirection: "row", flexFlow: "wrap" }}>
      <Button onClick={() => remove()} style={{ margin: 5 }} type="danger">
        -
      </Button>
      <span
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginRight: 20,
        }}
      >
        <label>min</label>
        <Input
          value={info?.min}
          onChange={(e) => setInfo({ ...info, min: e.target.value })}
          style={{ margin: 5, width: 50 }}
          size="medium"
        />
      </span>

      <span
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginRight: 20,
        }}
      >
        <label>max</label>
        <Input
          value={info?.max}
          onChange={(e) => setInfo({ ...info, max: e.target.value })}
          style={{ margin: 5, width: 50 }}
          size="medium"
        />
      </span>

      <span
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginRight: 20,
        }}
      >
        <label>type</label>
        <Select
          value={currentType}
          onChange={(e) => changeType(e)}
          style={{ width: 120, margin: 5 }}
        >
          {commissionTypes.map((type, idx) => (
            <Select.Option key={idx} value={type}>
              {type}
            </Select.Option>
          ))}
        </Select>
      </span>

      {currentType === "Constant" && (
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginRight: 20,
          }}
        >
          <label>constant</label>
          <Input
            value={info?.constant}
            onChange={(e) => setInfo({ ...info, constant: e.target.value })}
            style={{ margin: 5, width: 50 }}
            size="medium"
          />
        </span>
      )}

      {currentType === "Percentage" && (
        <>
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginRight: 20,
            }}
          >
            <label>percentage</label>
            <Input
              value={info?.percentage}
              onChange={(e) => setInfo({ ...info, percentage: e.target.value })}
              style={{ margin: 5, width: 50 }}
              size="medium"
            />
          </span>

          <span
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginRight: 20,
            }}
          >
            <label>max commission</label>
            <Input
              value={info?.maxCommission}
              onChange={(e) => setInfo({ ...info, maxCommission: e.target.value })}
              style={{ margin: 5, width: 50 }}
              size="medium"
            />
          </span>
        </>
      )}
    </div>
  );
};

const Container = styled.div`
  & .action_box {
    background-color: white;
    margin: 0px;
    padding: 10px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    & p {
      margin: 0;
    }
  }

  & .networks {
    background-color: white;
    margin: 0px;
    padding: 10px 10px;
    display: flex;
    flex-direction: column;

    margin-bottom: 20px;
    & p {
      margin: 0;
    }
    & span:first-child {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }

    & span:nth-child(2) {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      & button {
        margin-right: 10px;
      }
    }
  }
`;

const SSwitch = styled(Switch)`
  display: block;
  margin: auto;
`;
