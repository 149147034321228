import moment from "moment";

export function toPersianNum(input) {
  if (input === undefined) return;
  var returnModel = "",
    symbolMap = {
      1: "۱",
      2: "۲",
      3: "۳",
      4: "۴",
      5: "۵",
      6: "۶",
      7: "۷",
      8: "۸",
      9: "۹",
      0: "۰",
    };
  input = input.toString();
  for (var i = 0; i < input.length; i++)
    if (symbolMap[input[i]]) returnModel += symbolMap[input[i]];
    else returnModel += input[i];
  return returnModel;
}

export function toEnglishNum(input) {
  if (input === undefined) return;
  var returnModel = "",
    symbolMap = {
      "۱": "1",
      "۲": "2",
      "۳": "3",
      "۴": "4",
      "۵": "5",
      "۶": "6",
      "۷": "7",
      "۸": "8",
      "۹": "9",
      "۰": "0",
    };
  input = input.toString();
  for (var i = 0; i < input.length; i++)
    if (symbolMap[input[i]]) returnModel += symbolMap[input[i]];
    else returnModel += input[i];
  return returnModel;
}
export function formatMoney(amount, decimalCount = 0, decimal = ".", thousands = ",") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
}

export function persianFormatMoney(amount, decimalCount = 0, decimal = ".", thousands = ",") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return toPersianNum(
      negativeSign +
        (j ? i.substr(0, j) + thousands : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(amount - i)
              .toFixed(decimalCount)
              .slice(2)
          : "")
    );
  } catch (e) {
    console.log(e);
  }
}

export function getImageWithoutCache(url) {
  if (!url) return null;
  return process.env.REACT_APP_STORAGE + url + "?time=" + Date.now();
}

export function createDownloadLink(url) {
  return `${process.env.REACT_APP_STORAGE}${url}`;
}

export function getImage(url) {
  if (!url) return null;
  return process.env.REACT_APP_STORAGE + url;
}

export function transformCurrency(currencies, wallet) {
  let zero = 0;
  return currencies.map((_) => {
    _.balance = zero;
    if (wallet?.length) {
      for (let i = 0; i < wallet.length; i++) {
        if (_.nickName === wallet[i].currency) {
          _.balance = (wallet[i].balance / 100).toFixed(2);
        }
      }
    }

    return _;
  });
}
export const convertUnitTo = (data, unit) => {
  if (!data) {
    return;
  }
  let divider = 1;

  switch (unit) {
    case "KB":
      divider = Math.pow(1000, 1);
      break;
    case "MB":
      divider = Math.pow(1000, 2);
      break;
    case "GB":
      divider = Math.pow(1000, 3);
      break;
    case "TB":
      divider = Math.pow(1000, 4);
      break;
    default:
      break;
  }

  return Number(data / divider).toFixed(3) + " " + unit;
};
export const convertUnitToNoLable = (data, unit) => {
  if (!data) {
    return;
  }
  let divider = 1;

  switch (unit) {
    case "KB":
      divider = Math.pow(1000, 1);
      break;
    case "MB":
      divider = Math.pow(1000, 2);
      break;
    case "GB":
      divider = Math.pow(1000, 3);
      break;
    case "TB":
      divider = Math.pow(1000, 4);
      break;
    default:
      break;
  }

  return Number(data / divider).toFixed(3);
};
export function getDateAndTime(date) {
  if (!date) {
    return;
  }
  return moment(date).format("YY/MM/DD - HH:mm:ss");
}
export function getDurationFromExpire(expireDate) {
  if (!expireDate) {
    return null;
  }
  return moment(expireDate).diff(moment(), "days") + 1;
}
export function getExpiredTime(date, duration) {
  return moment(date).add(duration, "days").format("YY/MM/DD - hh:mm:ss");
}

export function getDuration(startDate, duration) {
  return moment(startDate).add(duration, "days").diff(moment(), "days") + 1;
}
export function getPersianDate(date) {
  moment.loadPersian({ usePersianDigits: true });

  return moment(date).format("YY/MM/DD");
}

export function getPersianDateAndTime(date) {
  // moment.loadPersian({ usePersianDigits: true });

  return moment(date).format("YY/MM/DD - hh:mm:ss");
}
export function getDate(date) {
  return moment(date).format("YY/MM/DD");
}

export function getRemainDays(date) {
  if (!date) {
    return null;
  }
  return moment(date).diff(moment(), "days");
}

export function newFormatMoney(amount, decimalCount, decimal = ".", thousands = ",") {
  try {
    // decimalCount = Math.abs(decimalCount);
    // decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    // let _price = amount.split('.')[1];

    let pureAmount = parseFloat(amount.toString());
    // console.log('>>>>>>>>>>>', pureAmount);

    // return (_price + '').split('.')[1].length;
    if (!decimalCount && decimalCount !== 0) {
      decimalCount = (pureAmount + "").split(".")[1]?.length || 0;

      decimalCount = decimalCount > 4 ? 4 : decimalCount;
      // console.log(amount, '>>>>>>>>>>>', decimalCount);
    }
    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    // console.log(e);
  }
}

export function invertColor(hex, bw) {
  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    return "#000000";
    // throw new Error("Invalid HEX color.");
  }
  var r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);
  if (bw) {
    // https://stackoverflow.com/a/3943023/112731
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
  }
  // invert color components
  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);
  // pad each with zeros and return
  return "#" + padZero(r) + padZero(g) + padZero(b);
}

function padZero(str, len) {
  len = len || 2;
  var zeros = new Array(len).join("0");
  return (zeros + str).slice(-len);
}
